<template>
  <div>
    <el-card class="box-card">
      <div slot="header" class="clearfix">
        <span>Subcategories</span>
      </div>
      <el-tree :data="data" node-key="id" default-expand-all :expand-on-click-node="false">
        <span class="custom-tree-node" slot-scope="{ node, data }">
          <span v-if="data.children && data.children.length > 0" style="font-weight: bold">
            {{ node.label }}
          </span>
          <span v-else>
            {{ node.label }}
          </span>
          <span v-if="data.children && data.children.length > 0">
            <el-button
              type="success"
              icon="el-icon-plus"
              size="mini"
              circle
              @click="
                () => {
                  dialogFormVisible = true;
                  selectedData = data;
                }
              "
            >
            </el-button>
          </span>
          <span v-else>
            <!-- <el-button type="primary" size="mini" icon="el-icon-edit" plain circle></el-button> -->
            <el-popconfirm title="Are you sure to delete this?" @confirm="() => remove(node, data)">
              <el-button type="text" size="mini" slot="reference" style="color: red" @click="selectedData = data"> delete </el-button>
            </el-popconfirm>
          </span>
        </span>
      </el-tree>
    </el-card>

    <!-- Subcategory name form -->
    <el-dialog title="Create new subcategory" :visible.sync="dialogFormVisible">
      <el-form :model="form">
        <el-form-item label="Subcategory name">
          <el-input v-model="form.name" autocomplete="off"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible = false">Cancel</el-button>
        <el-popconfirm title="Are you sure to save this?" @confirm="() => append()">
          <el-button type="primary" slot="reference">Confirm</el-button>
        </el-popconfirm>
      </span>
    </el-dialog>
    <!-- End -->
  </div>
</template>

<script>
import { getAllSubcategories, saveSubcategory, removeSubcategory } from "./lib";

export default {
  data() {
    return {
      data: [],
      dialogFormVisible: false,
      form: {
        name: "",
      },
      selectedData: {},
    };
  },

  methods: {
    async onInit() {
      await this.$store.dispatch("setLoading", true);
      this.data = await getAllSubcategories();
      await this.$store.dispatch("setLoading", false);
    },
    async append() {
      const response = await saveSubcategory({ name: this.form.name, category: { id: this.selectedData.id } });

      const obj = this.data.find((e) => e.id == this.selectedData.id);

      obj.children.push(response);
      this.dialogFormVisible = false;
    },

    async remove(node, data) {
      try {
        await removeSubcategory(data.id);
        const parent = node.parent;
        const children = parent.data.children || parent.data;
        const index = children.findIndex((d) => d.id === data.id);
        children.splice(index, 1);
      } catch (error) {
        console.log(error);
      }
    },
  },
  async mounted() {
    await this.onInit();
  },
};
</script>

<style>
.custom-tree-node {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 14px;
  padding-right: 8px;
}
</style>
